(window as any).dataLayer = [];
(window as any).digitalData = {
    page: {
        category: {
            primaryCategory: '',
            subCategory1: '',
            subCategory2: '',
            subCategory3: '',
        },
        pageInfo: {
            pageName: 'no pageName found',
        },
    },
    user: [
        {
            profile: [
                {
                    profileInfo: {},
                },
            ],
        },
    ],
};

// Google Tag Manager
if (process.env.ENABLE_GOOGLE_ANALYTICS === 'true') {
    if (process.env.NODE_ENV === 'production') {
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s) as HTMLScriptElement;
            const dl = l !== 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-T7BQCNL');
    } else {
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s) as HTMLScriptElement;
            const dl = l !== 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src =
                'https://www.googletagmanager.com/gtm.js?id=' +
                i +
                dl +
                '&gtm_auth=Y41a64UuJxUVudZw6tDIvw&gtm_preview=env-73&gtm_cookies_win=x';
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-T7BQCNL');
    }
}
